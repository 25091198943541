import { useEffect } from 'react'
import { Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useUpdateKybMutation } from '../../../services/guenoApi'
import TextInputForm from '../../../components/common/form/TextInputForm/TextInputForm'

const KybEditFieldModal = ({ initialValue, label, onSubmit, validateDate }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [_, { isLoading }] = useUpdateKybMutation({ fixedCacheKey: 'kyb-update' })

  const schema = yup.object().shape({
    textValue: validateDate
      ? yup
          .string()
          .matches(/^\d{2}\/\d{2}\/\d{4}$/, t('messages:kyb.dateOfRegistration.invalidDateFormat'))
          .test('valid-date', t('messages:kyb.dateOfRegistration.invalidDate'), (value) => {
            const [day, month, year] = value.split('/').map(Number)
            const date = new Date(year, month - 1, day)
            return date.getDate() === day && date.getMonth() === month - 1 && date.getFullYear() === year
          })
          .test('min-date', t('messages:kyb.dateOfRegistration.dateTooOld'), (value) => {
            const [day, month, year] = value.split('/').map(Number)
            const date = new Date(year, month - 1, day)
            const minDate = new Date(1850, 0, 1)
            return date >= minDate
          })
          .test('max-date', t('messages:kyb.dateOfRegistration.dateInFuture'), (value) => {
            const [day, month, year] = value.split('/').map(Number)
            const date = new Date(year, month - 1, day)
            const today = new Date()
            return date <= today
          })
          .required(t('common:required'))
      : yup.string(),
  })

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { textValue: initialValue },
  })

  useEffect(() => {
    setValue('textValue', initialValue, { shouldDirty: false })
  }, [initialValue, setValue])

  const handleFormSubmit = (data) => {
    onSubmit(label, data.textValue)
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Box className="modal-box">
        <TextInputForm
          name="textValue"
          control={control}
          label={t(`common:${label.split('.').pop()}`)}
          placeholder="DD/MM/AAAA"
        />
      </Box>
      <Box className="modal-button">
        <LoadingButton
          size="small"
          color="secondary"
          variant="contained"
          disabled={!isDirty || errors.textValue}
          type="submit"
          loading={isLoading}
        >
          {t('common:save')}
        </LoadingButton>
      </Box>
    </form>
  )
}

export default KybEditFieldModal
